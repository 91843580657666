// ================================================================================================
// 	File Name: ng-vertical-menu-template.scss
// 	Description: SCSS file for vertical-menu-template angular version only.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Stack - Responsive Admin Theme
// 	Version: 2.1
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.ngb-dp-header{
    padding-top: 5px !important;
	&.bg-light {
		background-color: #f8f9fa !important;
	}
	.btn-link{
		border: none !important;
	}
}
.ngb-tp{
    .btn-link{
		border: none !important;
	}
}
.tab-content{
	padding-top: 10px;
}