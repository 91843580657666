// ================================================================================================
//     File Name: tables.scss
//     Description: Tables pages custom scss
//     ----------------------------------------------------------------------------------------------
//     Item Name: Stack - Responsive Admin Theme
//     Version: 2.1
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

.table-white-space {
  th, td {
    white-space: nowrap;
  }
}

.table-borderless {
  td, th {
    border: none;
  }
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid $table_border_color;
  border-top: 1px solid $table_border_color;
}

.table-bordered {
  th, td {
    border: 1px solid $table_border_color;
  }
}

.table {
  th, td {
    border-bottom: 1px solid $table_border_color;
  }
}

.table-bordered {
  border: 1px solid $table_border_color;
}

.table-inverse {
  color: #eceeef;
  background-color: #00A5A8;
}

.table {
  &.table-column {
    th, td {
      border: none;
      border-left: 1px solid #e3ebf3;
    }
    th:first-child, td:first-child {
      border-left: none;
    }
  }
  th, td {
    border-top: table-borderless;
  }
  th, td {
    padding: 0.75rem 2rem;
  }
  &.table-xl {
    th, td {
      padding: 1.25rem 2rem;
    }
  }
  &.table-lg {
    th, td {
      padding: 0.9rem 2rem;
    }
  }
  &.table-de {
    th, td {
      padding: 0.75rem 2rem;
    }
  }
  &.table-sm {
    th, td {
      padding: 0.6rem 2rem;
    }
  }
  &.table-xs {
    th, td {
      padding: 0.4rem 2rem;
    }
  }
}

/*
* Table sizing
*/

// For table borderless

.table-borderless {
  thead th {
    border-top: 1px solid $table_border_color;
  }
  > tbody > tr > {
    td, th {
      border: 0;
    }
  }
}

// For table solid border

.table > {
  thead > tr {
    &.border-solid > th {
      border-bottom-width: 2px;
    }
    &.border-double > th {
      border-bottom-width: 4px;
      border-bottom-style: double;
    }
  }
  tbody > tr.border-dashed > td {
    border-top-style: dashed;
  }
}

//For table double border

//For table dashed border

.border-custom-color td {
  border-top: inherit;
  border-bottom: inherit;
}