/*=========================================================================================
	File Name: search.scss
	Description: Search  functionality.
	----------------------------------------------------------------------------------------
	Item Name: Stack - Responsive Admin Theme
	Version: 2.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

.navbar-container{

	a.nav-link-search{
		float: left;
	}
	.search-input{
		float: left;
    	padding-top: 1.2rem;
		width: 0;
		.input{
			width:0;			
			border: none;
			background: none;
			transition: all 0.2s ease-out;
			line-height: 16px;
		}
		&.open{
			.input{
				width: 200px;
				padding:5px 10px;						
				outline: none;
				background: none;				
    			transition: all 0.3s ease-out;
			}
		}
	}
}
.navbar-light, .navbar-semi-dark{
	.search-input{
		.input{
			color: #404E67;
		}
		&.open{
			.input{
				color: #404E67;
				border-bottom: 1px solid #404E67;
				
			}
		}
	}
}
.navbar-dark, .navbar-semi-light{
	.search-input{
		.input{
			color: #F5F7FA;
		}
		&.open{
			.input{
				color: #F5F7FA;
				border-bottom: 1px solid #F5F7FA;
			}
		}
	}
}

@include media-breakpoint-down(md) {
	#navbar-mobile{
		.search-input.open{
			.input{
				width: 180px;
				color: #404e67;
				border-bottom: 1px solid #404e67;
				overflow: hidden;
				position: relative;
				background: #fff;
				z-index: 1;
				padding: 8px 10px;
				top: -13px;
			}
		}
	}
}