/* ===============================================================================================
    File Name: navbar.scss
    Description: Contain header navigation bar, vertical main navigation bar and
    horiznotal main navigation bar related SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Stack - Responsive Admin Theme
    Version: 2.1
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/

%extend_hamburger_color {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: $navbar-light-bg;
    }
}

.header-navbar {
    padding: 0px;
    // min-height: $navbar-height;
    font-family: $font-family-monospace;
    transition: 300ms ease all;
    .navbar-wrapper {
        width: 100%;
    }
    // add border to navbar
    &.navbar-border {
        border-bottom: 1px solid #E4E7ED;
    }
    &.navbar-dark.navbar-border{
        border-bottom: 1px solid #8596B5;
    }
    // add shadow to navbar
    &.navbar-shadow {
        box-shadow: 2px 4px 20px -4px rgba(0, 0, 0, 0.1)
    }
    // hide top horizontal menu drop-down arrow
    &.navbar-without-dd-arrow {
        .nav-link {
            &.dropdown-toggle::after {
                display: none;
            }
        }
    }
    //center align brand option for horizontal layouts
    &.navbar-brand-center {
        .navbar-header {
            width: 150px;
            position: absolute;
            left: 50%;
            padding: 0;
            margin: 0;
            transform: translate(-50%, 0);
        }
    }
    .navbar-header {
        height: 100%;
        width: $navbar-header-width;
        // height: $navbar-height;
        float: left;
        position: relative;
        padding: 0rem 1rem;
        transition: 300ms ease all;
        .navbar-brand {
            padding: 15px 0px;
            .brand-logo{
                vertical-align: sub;
            }
            .brand-text{
                display: inline;
                padding-left: 10px;
                font-weight: 500;
                letter-spacing: 1px;
            }
        }
    }
    .navbar-container {
        padding: 0rem 18px;
        // height: $navbar-height;
        transition: 300ms ease all;
        background: inherit;
        ul.nav {
            li {
                > a.nav-link {
                    padding: 1.4rem 0.6rem;
                    i.flag-icon {
                        margin-right: 0.5rem;
                    }
                }
                a.dropdown-user-link {
                    padding: 1.4rem 0.6rem;
                    .user-name {
                        display: inline-block;
                        margin-bottom: 0.4rem;
                        margin-left: 0.2rem;
                    }
                }
                a.menu-toggle {
                    padding: 1.1rem 0.8rem 1rem 1rem;
                    i {
                        font-size: 1.6rem;
                    }
                }
                a.nav-link-label {
                    padding: 1.4rem 0rem 1rem 0.8rem;
                }
                a.dropdown-user-link {
                    .avatar {
                        margin-right: 0.5rem;
                    }
                }
                a.nav-link-search,
                a.nav-link-expand {
                    padding: 1.4rem 1rem 1.3rem 1rem;
                }
                div.input-group {
                    padding: 0.7rem 1rem;
                }
                i.ficon {
                    font-size: 1.2rem;
                }
                .media-list {
                    max-height: 18.2rem;
                }
                .scrollable-container {
                    position: relative;
                }
            }
        }
        //Navbar notification css
        .dropdown-menu-media {
            width: 24rem;
            .media-list {
                .media {
                    padding: 1rem;
                    border: none;
                    // border-top: 1px solid $border-color;
                    border-bottom: 1px solid $border-color;
                }
            }
            .dropdown-menu-header {
                border-bottom: 1px solid $border-color;
            }
            .dropdown-menu-footer {
                a {
                    padding: 0.3rem;
                    border-top: 1px solid $border-color;
                }
            }
        }
    }
    &.navbar-without-menu {
        .navbar-container {
            margin-left: 0;
        }
    }
    &.navbar-with-menu {
        .navbar-container {}
    }
    .nav-item + .nav-item {
        margin-left: 0rem;
    }
    /* For sm screen*/
    @include media-breakpoint-down(sm) {
        .navbar-header {
            width: 100% !important;
            padding: 0.5rem 1rem;
            .menu-toggle {
                top: 2px;
                position: relative;
            }
            .open-navbar-container i {
                font-size: 1.8rem;
            }
            .navbar-brand {
                position: absolute;
                left: 50%;
                top:0;
                transform: translate(-50%, 0);
            }
        }
        &.navbar-with-menu {
            .navbar-container {
                width: 100%;
                display: table;
                background: #fff;
                padding: 0rem 1rem;
                margin: 0;
                height: auto;
            }
        }
    }
}
.navbar-light {
        background: $navbar-light-bg;
        &.navbar-horizontal {
            background: $navbar-light-bg;
        }
        .navbar-nav {
            li {
                line-height: 1;
            }
            .active {
                &.nav-link {
                    background-color: rgba(0, 0, 0, 0.03);
                }
            }
            .disabled {
                &.nav-link {
                    color: $nav-link-disabled-color;
                }
            }
        }
    }
.navbar-dark {
    background: $navbar-dark-bg;
    .navbar-brand{
        color: $navbar-light-bg !important;
    }
    &.navbar-horizontal {
        background: $navbar-dark-bg;
    }
    @extend %extend_hamburger_color;
    .nav-search {
        .form-control,
        .btn-secondary {
            color: $navbar-light-bg;
            background: $navbar-dark-bg;
        }
    }
    .navbar-nav {
        li {
            line-height: 1;
        }
        .active {
            &.nav-link {
                background-color: rgba(255, 255, 255, 0.05);
            }
        }
        .disabled {
            &.nav-link {
                color: $gray-200;
            }
        }
    }
}
.navbar-semi-dark {
    .navbar-header {
        background: $navbar-dark-bg;
        .brand-text{
            color: $navbar-light-bg;
        }
    }
    background:$navbar-light-bg;
    .navbar-nav {
        li {
            line-height: 1;
        }
        .nav-link {
            color: $gray-600;
        }
        .active {
            &.nav-link {
                background-color: rgba(0, 0, 0, 0.03);
            }
        }
        .disabled {
            &.nav-link {
                color: $nav-link-disabled-color;
            }
        }
    }
}
.navbar-semi-light {
    .navbar-header {
        background: $navbar-light-bg;
        .brand-text{
            color: $navbar-dark-bg;
        }
    }
    background: $gray-600;
    .navbar-nav {
        li {
            line-height: 1;
        }
        .nav-link {
            color: $navbar-light-bg;
        }
        .active {
            &.nav-link {
                background-color: rgba(0, 0, 0, 0.03);
            }
        }
        .disabled {
            &.nav-link {
                color: $nav-link-disabled-color;
            }
        }
    }
}

// Mega menu
// ------------------------------
.mega-dropdown {
    position: static !important;
    .dropdown-menu{
        display: flex;
    }
}
.navigation-main, .navbar-nav{
    .mega-dropdown-menu {
        font-family: $font-family-sans-serif;
        width: calc(100% - 50px);
        padding: 0.5rem 0;
        // width: 98%;
        /* fallback if needed */
        margin: 0px 25px;
        box-shadow: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        z-index: 9999;
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                a {
                    display: block;
                    color: $gray-800;
                    .children-in {
                        float: right;
                    }
                    &:hover {
                        text-decoration: none;
                        color: $gray-600;
                    }
                    &:focus {
                        text-decoration: none;
                        color: $gray-600;
                    }
                }
            }
            a.dropdown-item {
                padding: 10px;
            }
        }
        p.accordion-text {
            line-height: $line-height-base;
        }
        .dropdown-menu-header {
            padding: 0.5rem 0;
            border-bottom: 1px solid $border-color;
        }
    }
}
// TODO: Need to generalize it
.horizontal-top-icon-menu{
    .mega-dropdown-menu {
        margin: 0px 25px !important;
    }
}

// Mega menu max width on boxed layout only
.container {
    .mega-dropdown-menu {
        @include mega-menu-container-max-widths();
    }
}

@-moz-document url-prefix() { 
     ul {
        li {
            a {
                .children-in {
                    position: relative;
                    right: 3px;
                    top: -14px;
                }
            }
        }
    }
}

// Mega menu list
// ------------------------------
.menu-list {
    list-style: none;
    padding: 0;
    overflow: hidden;
}

.menu-panel {
    padding: 0;
}

.sliding-menu {
    overflow: hidden;
    position: relative;
    ul {
        float: left;
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            margin: 0;
        }
    }
}

// Mega "Hamburger" Icon Animations
// ------------------------------
.navbar-menu-icon {
    width: 24px;
    margin: 16px 2px !important;
    position: relative;
    margin: 0px auto;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}

.navbar-menu-icon {
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $gray-600;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }
}

.navbar-menu-icon span:nth-child(1) {
    top: 0px;
}

.navbar-menu-icon span:nth-child(2) {
    top: 10px;
}

.navbar-menu-icon span:nth-child(3) {
    top: 20px;
}

.navbar-menu-icon.show span:nth-child(1) {
    top: 10px;
    transform: rotate(135deg);
}

.navbar-menu-icon.show span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.navbar-menu-icon.show span:nth-child(3) {
    top: 10px;
    transform: rotate(-135deg);
}

// Media queries for device support
// =========================================
@include media-breakpoint-down(sm) {
    .header-navbar {
        // generic navbar dropdown specific
        .navbar-nav {
            .show {
                position: static;
            }
            .open-navbar-container {
                padding-top: 0.625rem;
            }
        }
        .navbar-container {
            .show {
                .dropdown-menu {
                    right: 0;
                    left: 0 !important;
                    float: none;
                    width: auto;
                    margin-top: 0;
                    max-height: 400px;
                    overflow-x: hidden;
                    overflow-y: scroll;
                }
            }
            ul.nav {
                li {
                    &.nav-item i {
                        margin-right: 0.2rem;
                    }
                    > a.nav-link {
                        padding: 1.2rem 0.4rem;
                        i.flag-icon {
                            margin-right: 0.2rem;
                        }
                    }
                    a.dropdown-user-link {
                        padding: 1.2rem 0.4rem;
                    }
                    a.nav-link-label {
                        padding: 1.4rem 0rem 1rem 0.8rem
                    }
                    a.nav-link-search,
                    a.nav-link-expand {
                        padding: 1.4rem 1rem 1rem 1rem
                    }
                    .dropdown-toggle::after {
                        margin-right: 0;
                        margin-left: -2px;
                    }
                }
            }
        }
    }
    // dark navbar
    .navbar-dark,
    .navbar-semi-dark {
        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $navbar-light-bg;
        }
        .navbar-header {
            .navbar-nav {
                .nav-link {
                    color: $navbar-light-bg;
                }
                // .brand-text{
                //     color: $navbar-dark-bg;
                // }
            }
        }
        .navbar-container {
            .navbar-nav {
                .nav-link {
                    color: $gray-600;
                }
            }
        }
    }
    // light navbar
    .navbar-light,
    .navbar-semi-light {            
        .navbar-header {
            .navbar-nav {
                .nav-link {
                    color: $navbar-dark-bg;
                }
                // .brand-text{
                //     color: $navbar-light-bg;
                // }
            }
        }
        .navbar-container {
            .navbar-nav {
                .nav-link {
                    color: $gray-600;
                }
            }
        }
    }
}

.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}

.headroom--pinned-top {
    transform: translateY(0%);
}

.headroom--unpinned-top {
    transform: translateY(-100%);
}

.headroom--pinned-bottom {
    transform: translateY(0%);
}

.headroom--unpinned-bottom {
    transform: translateY(100%);
}

// iPad support for navbr
@include media-breakpoint-down(lg) {
    .header-navbar {
        .navbar-container {
            ul.nav {
                li {
                    > a.nav-link {
                        padding: 1.4rem 0.4rem 0.8rem 0.4rem;
                        i.flag-icon {
                            margin-right: 0.2rem;
                        }
                    }
                    a.dropdown-user-link {
                        padding: 1.4rem 0.4rem 0.8rem 0.4rem;
                    }
                    a.nav-link-label {
                        padding: 1.2rem 0rem 1rem 0.8rem;
                    }
                    a.nav-link-search,
                    a.nav-link-expand {
                        padding: 1.5rem 1rem 1rem 1rem;
                    }
                    .dropdown-toggle::after {
                        margin-right: 0;
                        margin-left: -2px;
                    }
                }
            }
        }
    }
}

// For Medium and down
@include media-breakpoint-down(lg) {
    .header-navbar {
        .navbar-container {
            ul.nav {
                li {
                    .selected-language {
                        display: none;
                    }
                    a.dropdown-user-link {
                        .user-name {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@mixin extend_mobile_specific($menu_height) {
    .header-navbar {
        .navbar-container {
            .show {
                .dropdown-menu {
                    max-height: $menu_height;
                }
            }
        }
    }
}

@media (max-width: 767px) {    
    //For responsive mega menu
    #navbar-mobile.navbar-collapse {           
        .navbar-nav {
            margin: 0;
            flex-flow: row wrap;
            .dropdown-menu {
                position: absolute;
            }
            .nav-item {
                float: left;
            }
        }
    }
}


/* ----------- iPhone 5, 5S  iPhone 6----------- */


/* Landscape */

@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    @include extend_mobile_specific(180px);
}


/* ----------- iPhone 6+ ----------- */


/* Landscape */

@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    @include extend_mobile_specific(280px);
}
