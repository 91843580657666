// ================================================================================================
//     File Name: Footer.scss
//     Description: *  Main footer styles.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Stack - Responsive Admin Theme
//     Version: 2.1
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Stick footer to the bottom of the page
footer{
	&.footer {
		padding: 0.4rem;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 35px; /* Set the fixed height of the footer here */
	}

	//shadow & border options
	&.navbar-shadow {
		box-shadow: 0px -1px 4px 0px rgba(0,0,0,0.15);
	}
	&.navbar-border {
		border-top: 1px solid #E4E7ED;
	}

	// Footer color options
	&.footer-transparent{
		border: none;
	}
	&.footer-light{
		background: $footer-color-light-bg;
	}
	&.footer-dark{
		background: $footer-color-dark-bg;
		color: #fff;
	}
}