/*=========================================================================================
	File Name: horizontal-menu.scss
	Description: A classic horizontal menu for easy navingation & support all devices.
	It support light & dark version, filpped layout, right side icons, borders menu for
	item seperation.
	----------------------------------------------------------------------------------------
	Item Name: Stack - Responsive Admin Theme
	Version: 2.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// Core variables and mixins
@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";
@import "../../../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../../variables/variables";
@import "../../variables/components-variables";

// Import first main menu mixin
@import "../../mixins/main-menu-mixin";

/* html body.horizontal-menu{
	padding-top:0;
} */
// Horizontal Menu
//=========================
.horizontal-menu{
	&.menu-collapsed{
		#main-menu-navigation{
			.nav-item{
				a span{
					display: none;
				}
			}			
		}
	}
	.navigation-header{
		font-family: inherit;
    	color: #929292;
    	padding: 8px 20px;
    	font-size: 1rem;
    	text-transform: uppercase;
	}
	.navbar-flipped{
		.navbar-nav{
			.nav-item{
				float:right;
			}
		}
	}
	.navbar-light{
		ul#main-menu-navigation > li.active > a{
			border-bottom: 2px solid $primary;
			background: $white;
		}
		.active > a{
			background: whitesmoke;
			font-weight: 500;
		}		
	}
	.navbar-dark{
		.nav-item{
			&.active > a{
				border-bottom: 2px solid $primary;
				background-color: #313C50;
			}
		}
		.active > a{
			background: whitesmoke;
			font-weight: 500;
		}
	}
}

// Import vertical-overlay-menu.scss for small screen support
@import "vertical-overlay-menu.scss";