// ================================================================================================
//     File Name: content.scss
//     Description: Page content level SCSS for different screen size, layout and device.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Stack - Responsive Admin Theme
//     Version: 2.1
//     Author: PIXINVENT
//     Authuseror URL: http://www.themeforest.net//pixinvent
// ================================================================================================

// Contest margin left for expanded & collapsed stats width & margin left 
// ================================================================================================
html {
    font-size: 14px;
    height: 100%;
    body {
        height: 100%;
        min-height: 100%;
        position: relative;
        // padding-top: 0;
        &.fixed-navbar{
            padding-top: $navbar-height;
        }
        background-color: $content-bg;
        direction: $body-direction;
        .content {
            padding: 0;
            position:relative;
            transition: 300ms ease all;
            backface-visibility: hidden;
            min-height: calc(100% - 32px);
            &.app-content{
                overflow: hidden;
            }
            .content-wrapper {
                padding: 1.8rem;
                .content-header-title{
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: $gray-800;
                    
                }
                .content-header-right{
                    // margin-top: 6px;
                }
            }
        }
        //Remove left margin for 1 column layout
        &[data-col="1-column"] {
            &:not(.vertical-content-menu) {
                .content,
                .footer {
                    margin-left: 0px !important;
                }
            }
            &.horizontal-layout {
                .content,
                .footer {
                    margin: 0 auto !important;
                }
            }
        }
        &.boxed-layout {
            padding-left: 0;
            padding-right: 0;
        }
        &.bg-full-screen-image {
            // background: url(./assets/app/images/backgrounds/bg-2.jpg) no-repeat center center fixed;
            background-size: cover;
        }
        .pace {
            .pace-progress {
                background: #4ACACC;
            }
        }

    }
}

/*
* Blank page
*/

.blank-page {
    .content-wrapper {
        padding: 0 !important;
        .flexbox-container {
            display: flex;
            align-items: center;
            height: 100vh;
        }
    }
}
.app-content.center-layout{
    overflow: hidden;
}

// Reduce the content padding for mobile screen
@include media-breakpoint-down(sm) {
    html {
        body {
            .content {
                .content-wrapper {
                    padding: 1rem;
                }
            }
            footer {
                text-align: center;
            }
        }
    }
}


/*
* Col 3 layout for detached and general type
*/

@include media-breakpoint-up(lg) {
    body {
        // Normal sidebar
        .content-right {
            width: calc(100% - #{$sidebar-width});
            float: right;
        }
        .content-left {
            width: calc(100% - #{$sidebar-width});
            float: left;
        }
        // Detached sidebar
        .content-detached {
            width: 100%;
            &.content-right {
                float: right;
                margin-left: -$sidebar-width;
                .content-body {
                    margin-left: $sidebar-width+20;
                }
            }
            &.content-left {
                float: left;
                margin-right: -$sidebar-width;
                .content-body {
                    margin-right: $sidebar-width+20;
                }
            }
        }
    }
    .sidebar-right.sidebar-sticky {
        float: right !important;
        margin-left: -$sidebar-width;
        width: $sidebar-width !important;
    }
    //left sticky sidebar
    [data-col="content-left-sidebar"] {
        .sticky-wrapper {
            float: left;
        }
    }
}
