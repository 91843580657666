/* ===============================================================================================
	File Name: list-group.scss
	Description: Contain list item, list group related extended SCSS.
	----------------------------------------------------------------------------------------------
	Item Name: Stack - Responsive Admin Theme
	Version: 2.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/

// Inline list style with pipeline seperator

ul.list-inline{
	li{
		display: inline-block;
	}
	&.list-inline-pipe{
		>li + li:before{
			content: ' | ';
		    color: $gray-300;
		}
	}
}