/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Stack - Responsive Admin Theme
  Version: 2.1
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
  ----------------------------------------------------------------------------------------

  Modifiers:       `progress-lg`
                   `progress-md`
                   `progress-sm`
                   `progress-xs`

==========================================================================================*/

// Size Modifier
// ========================================================================

.progress{
  margin-bottom: 3rem;
  &:last-child{
    margin-bottom: 1rem;
  }
  &.progress-xl{
    height: $progress-size-xl;
  }
  &.progress-lg{
    height: $progress-size-lg;
  }
  &.progress-md{
    height: $progress-size-md;
  }
  &.progress-sm{
    height: $progress-size-sm;
  }
  &.progress-xs{
    height: $progress-size-xs;
  }
}